const skills = [
  { name: "Javascript", link: "" },
  { name: "Node", link: "" },
  { name: "Gatsby", link: "" },
  { name: "Microservices", link: "" },
  { name: "AWS", link: "" },
  { name: "Linux", link: "" },
  { name: "ReST", link: "" },
  { name: "HAL", link: "" },
  { name: "Swagger", link: "" },
  { name: "C#", link: "" },
  { name: "Visual Studio Code", link: "" },
  { name: "Chrome plugins", link: "" },
  { name: "Elastic Beanstalk", link: "" },
  { name: "Docker", link: "" },
  { name: "Lambda", link: "" },
  { name: ".NET", link: "" },
  { name: "terraform", link: "" },
  { name: "mongodb", link: "" },
  { name: "JIRA", link: "" },
  { name: "Confluence", link: "" },
  { name: "CMake", link: "" },
  { name: "gcc", link: "" },
  { name: "Java", link: "" },
  { name: "C++", link: "" },
  { name: "python", link: "" },
  { name: "PostgreSQL", link: "" },
  { name: "Chrome", link: "" },
  { name: "Xcode", link: "" },
  { name: "Code Blocks", link: "" },
]
export default skills
